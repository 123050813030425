import React from "react"
import styled from "styled-components"

// Components
import { Paragraph } from "../typography"

const Wrapper = styled.div`
  background: ${props => props.background || props.theme.colors.main};
  color: ${props => props.textColor || props.theme.colors.white};
  text-align: ${props => props.alignment || `center`};
  padding: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .content {
    max-width: 960px;
  }
`

const LeadSection = ({ input }) => {
  return (
    <Wrapper background={input.backgroundColor} color textAlign>
      <div className="content">
        <Paragraph color={props => props.theme.colors.white}>
          {input.text}
        </Paragraph>
      </div>
    </Wrapper>
  )
}

export default LeadSection
