import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

// Components
import Button from "../button"
import { H3, Paragraph } from "../typography"

const Wrapper = styled.div`
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  max-width: 920px;
  align-items: center;

  @media (max-width: 750px) {
    margin: 0;
  }
`

const ImageWrapper = styled(Img)`
  ${props =>
    props.alignment === "Left"
      ? `
      grid-column: 3 / span 6;
      @media (max-width: 750px) {
        grid-column: 1 / span 8;
        grid-row: 1 / span 3;
      }
      `
      : `
      grid-column: 1 / span 6;
      @media (max-width: 750px) {
        grid-column: 1 / span 8;
        grid-row: 1 / span 3;
      }
  `};
  grid-row: 1 / span 3;
`

const Content = styled.div`
  background: ${props => props.theme.colors.lightAccent};
  padding: 2.5rem;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.05);
  ${props =>
    props.alignment === "Left"
      ? `
      grid-column: 1 / span 4;
      @media (max-width: 750px) {
        grid-column: 1 / span 8;
      }
    `
      : `
      grid-column: 5 / span 4;
      @media (max-width: 750px) {
        grid-column: 1 / span 8;
      }
    `}
  grid-row: 3 / span 2;
  z-index: 2;
`

const ButtonLink = styled(Button)`
  text-decoration: none;
`

const Feature = ({ input, alignment }) => {
  return (
    <Wrapper>
      <Content alignment={input.alignment}>
        <H3>{input.headline}</H3>
        <Paragraph>{input.text}</Paragraph>
        <ButtonLink as={Link} to={input.button.link}>
          {input.button.text}
        </ButtonLink>
      </Content>
      <ImageWrapper
        fluid={input.image.asset.fluid}
        alt={input.image.title}
        alignment={input.alignment}
      />
    </Wrapper>
  )
}

export default Feature
