import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const SEO = ({ input, lang, title }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={input.title}
      titleTemplate={`%s | Laurel Tindall`}
      meta={[
        {
          name: `description`,
          content: input.description,
        },
      ]}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `Laurel Tindall, celebrating 46 years of SPU Gymnastics.`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
