import React from "react"
import styled from "styled-components"

// Components
import { H1, Paragraph } from "../typography"

const Content = styled.div`
  color: ${props => props.theme.colors.white};
  grid-column: 3 / span 6;

  @media (max-width: 900px) {
    grid-column: 2 / span 8;
  }

  @media (max-width: 750px) {
    grid-column: span 12;
  }
`

const Hero = ({ input }) => {
  const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    background: url(${input.image.asset.url});
    background-size: cover;
    height: 75vh;

    @media (max-width: 900px) {
      height: 100%;
      padding: 5rem;
    }

    @media (max-width: 750px) {
      padding: 2.5rem;
      height: 100%;
    }
  `
  return (
    <Wrapper>
      <Content>
        <H1 color={props => props.theme.colors.white}>{input.headline}</H1>
        <Paragraph lead color={props => props.theme.colors.white}>
          {input.subheading}
        </Paragraph>
      </Content>
    </Wrapper>
  )
}

export default Hero
