import React from "react"
import { graphql } from "gatsby"

// Components
import { Layout } from "../components/layout"
import { SEO } from "../components/meta"
import Hero from "../components/hero"
import LeadSection from "../components/leadSection"
import Feature from "../components/feature"

const IndexPage = ({ data }) => {
  const doc = data.sanityHome
  if (!doc) return null

  const features = doc.features.map(feature => {
    return <Feature input={feature} />
  })

  return (
    <Layout>
      <SEO input={doc.seo} />
      <Hero input={doc.hero} />
      <LeadSection input={doc.lead} />
      {features}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    sanityHome {
      seo {
        title
        description
      }
      hero {
        headline
        subheading
        image {
          asset {
            title
            url
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
      lead {
        backgroundColor
        alignment
        text
        textColor
      }
      features {
        headline
        text
        alignment
        button {
          link
          text
        }
        image {
          asset {
            title
            url
            fluid(maxWidth: 685, maxHeight: 460) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
